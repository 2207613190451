import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    storiesById: {},
    storiesInfoByJuiceId: {},
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_JUICE_STORY: {
            const { storiesById } = state;
            const { story } = action.payload;

            return {
                ...state,
                storiesById: {
                    ...storiesById,
                    [story.id]: story,
                },
            };
        }

        /* --- */

        case types.SET_JUICE_STORIES_INFO_BY_JUICE_ID_LOADING: {
            const { juiceId } = action.payload;

            const prevByStoryId = state.storiesById[juiceId] || {};

            return {
                ...state,
                storiesInfoByJuiceId: {
                    ...state.storiesInfoByJuiceId,
                    [juiceId]: {
                        ...prevByStoryId,
                        error: "",
                        isLoading: true,
                    },
                },
            };
        }

        case types.SET_JUICE_STORIES_INFO_BY_JUICE_ID: {
            const {
                juiceId,
                data,
                error,
            } = action.payload;

            const prevByJuiceId = state.storiesById[juiceId] || {};

            return {
                ...state,
                storiesInfoByJuiceId: {
                    ...state.storiesInfoByJuiceId,
                    [juiceId]: {
                        ...prevByJuiceId,
                        data,
                        error,
                        isLoading: false,
                        isLoaded: true,
                    },
                },
            };
        }

        case types.SET_JUICE_STORIES_INFO_STORY_RATING: {
            const {
                juiceId,
                storyId,
                rating,
            } = action.payload;

            const prevByJuiceId = state.storiesInfoByJuiceId[juiceId] || {};

            if (prevByJuiceId.data?.stories?.[storyId]) {
                prevByJuiceId.data.stories[storyId].storyRating = rating;
            }

            return {
                ...state,
                storiesInfoByJuiceId: {
                    ...state.storiesInfoByJuiceId,
                    [juiceId]: prevByJuiceId,
                },
            };
        }

        case types.SET_JUICE_STORIES_INFO_STORY_VIEWED: {
            const {
                juiceId,
                storyId,
            } = action.payload;

            const prevByJuiceId = state.storiesInfoByJuiceId[juiceId] || {};

            if (prevByJuiceId.data?.stories?.[storyId]) {
                prevByJuiceId.data.stories[storyId].isOpenedStory = true;
            }

            return {
                ...state,
                storiesInfoByJuiceId: {
                    ...state.storiesInfoByJuiceId,
                    [juiceId]: prevByJuiceId,
                },
            };
        }

        case types.SET_JUICE_STORIES_INFO_STORY_VIDEO_VIEWED: {
            const {
                juiceId,
                storyId,
            } = action.payload;

            const prevByJuiceId = state.storiesInfoByJuiceId[juiceId] || {};

            if (prevByJuiceId.data?.stories?.[storyId]) {
                prevByJuiceId.data.stories[storyId].isVideoViewed = true;
            }

            return {
                ...state,
                storiesInfoByJuiceId: {
                    ...state.storiesInfoByJuiceId,
                    [juiceId]: prevByJuiceId,
                },
            };
        }

        case types.SET_JUICE_STORIES_INFO_STORY_EXTRA_JUICE_RATING: {
            const {
                juiceId,
                storyId,
                rating,
            } = action.payload;

            const prevByJuiceId = state.storiesInfoByJuiceId[juiceId] || {};

            if (prevByJuiceId.data?.stories?.[storyId]) {
                prevByJuiceId.data.stories[storyId].extraJuiceRating = rating;
            }

            return {
                ...state,
                storiesInfoByJuiceId: {
                    ...state.storiesInfoByJuiceId,
                    [juiceId]: prevByJuiceId,
                },
            };
        }

        case types.SET_JUICE_STORIES_INFO_STORY_ANSWER: {
            const {
                juiceId,
                storyId,
                answerId,
            } = action.payload;

            const prevByJuiceId = state.storiesInfoByJuiceId[juiceId] || {};

            if (prevByJuiceId.data?.stories?.[storyId]) {
                prevByJuiceId.data.stories[storyId].quizAnswerId = answerId;
            }

            return {
                ...state,
                storiesInfoByJuiceId: {
                    ...state.storiesInfoByJuiceId,
                    [juiceId]: prevByJuiceId,
                },
            };
        }

        case types.SET_JUICE_STORIES_INFO_STORY_POLL_ANSWER: {
            const {
                juiceId,
                storyId,
                answerId,
            } = action.payload;

            const prevByJuiceId = state.storiesInfoByJuiceId[juiceId] || {};

            if (prevByJuiceId.data?.stories?.[storyId]) {
                prevByJuiceId.data.stories[storyId].pollAnswerId = answerId;
            }

            return {
                ...state,
                storiesInfoByJuiceId: {
                    ...state.storiesInfoByJuiceId,
                    [juiceId]: prevByJuiceId,
                },
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
