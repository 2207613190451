const rateExtraJuice = (services, params) => {
    const { api, actions } = services;
    const { extraJuiceId, rating } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.extraJuices.setExtraJuiceRate({
            id: extraJuiceId,
            rating,
        }));

        api.rate.rateExtraJuice({
            session: state.user.session,
            extraJuiceId,
            rating,
        });
    };
};

/* --- */

const rateAssignmentExtraJuice = (services, params) => {
    const { api, actions } = services;
    const {
        groupCode,
        assignmentId,
        storyId,
        extraJuiceId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.assignments.setAssignmentByGroupCodeStoryExtraJuiceRate({
            groupCode,
            storyId,
            extraJuiceId,
            rating,
        }));

        api.rate.rateAssignmentExtraJuice({
            session: state.user.session,
            assignmentId,
            extraJuiceId,
            rating,
        });
    };
};

const rateAssignmentStory = (services, params) => {
    const { api, actions } = services;
    const {
        groupCode,
        assignmentId,
        storyId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.assignments.setAssignmentByGroupCodeStoryRate({
            groupCode,
            storyId,
            rating,
        }));

        api.rate.rateAssignmentStory({
            session: state.user.session,
            assignmentId,
            storyId,
            rating,
        });
    };
};

/* --- */

const rateDailyJuiceExtraJuice = (services, params) => {
    const { api, actions } = services;
    const {
        juiceId,
        storyId,
        extraJuiceId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juiceStories.setStoryInfoStoryExtraJuiceRate({
            juiceId,
            storyId,
            rating,
        }));

        api.rate.rateJuiceExtraJuice({
            session: state.user.session,
            juiceId,
            extraJuiceId,
            rating,
        });
    };
};

const rateDailyJuiceStory = (services, params) => {
    const { api, actions } = services;
    const {
        juiceId,
        storyId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juiceStories.setStoryInfoStoryRate({
            juiceId,
            storyId,
            rating,
        }));

        api.rate.rateJuiceStory({
            session: state.user.session,
            juiceId,
            storyId,
            rating,
        });
    };
};

/* --- */

const rateSearchStory = (services, params) => {
    const { api, actions } = services;
    const {
        juiceId,
        storyId,
        search,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.search.setSearchStoryRating({
            storyId,
            search,
            rating,
        }));

        api.rate.rateSearchStory({
            session: state.user.session,
            juiceId,
            storyId,
            rating,
        });
    };
};

const rateSearchStoryExtraJuice = (services, params) => {
    const { api, actions } = services;
    const {
        juiceId,
        extraJuiceId,
        rating,
    } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.search.setSearchStoryExtraJuiceRating({
            extraJuiceId,
            rating,
        }));

        api.rate.rateSearchStory({
            session: state.user.session,
            juiceId,
            storyId: extraJuiceId,
            rating,
        });
    };
};

/* --- */

export default {
    rateExtraJuice,
    rateAssignmentStory,
    rateAssignmentExtraJuice,
    rateDailyJuiceStory,
    rateDailyJuiceExtraJuice,
    rateSearchStory,
    rateSearchStoryExtraJuice,
};
